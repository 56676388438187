import React from "react";
import "./About.css";
const About = () => {
  return (
    <div className="big-container">
      <div className="about">
        <div className="about-title">Welcome to Pita Melt</div>
        <div className="about-body">
          Your gateway to a premium Mediterranean adventure. Located in the
          heart of Calgary, we invite you to try our carefully crafted dishes,
          made from the best ingredients. Beware however, you might get
          addicted!
        </div>
      </div>
      
    </div>
  );
};

export default About;
